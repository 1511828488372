<template>
  <div class="section">
    <h2 class="has-text-centered">404: Page Not Found</h2>
    <h3 class="has-text-centered">We were unable to locate the page you requested.</h3>
    <div class="bottom-links has-text-centered"><router-link to="/">Return to Homepage</router-link></div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
